<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 mt-4">
                <!-- <a class="footer-logo text-uppercase" href="#">
                    <i class="mdi mdi-alien"></i>Hiric
                </a> -->
                <img routerLink="/" style="width: 6rem;" class="w-12" src="/assets/images/logo.png" alt="">

                <div class="text-muted mt-4">
                    <ul class="list-unstyled footer-list">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">About us</a></li>
                        <li><a href="#">Careers</a></li>
                        <li><a href="#">Contact us</a></li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-3 mt-4">
                <h4>Information</h4>
                <div class="text-muted mt-4">
                    <ul class="list-unstyled footer-list">
                        <li><a href="#">Terms & Condition</a></li>
                        <li><a href="#">About us</a></li>
                        <li><a href="#">Jobs</a></li>
                        <li><a href="#">Bookmarks</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 mt-4">
                <h4>Support</h4>
                <div class="text-muted mt-4">
                    <ul class="list-unstyled footer-list">
                        <li><a href="">FAQ</a></li>
                        <li><a href="">Contact</a></li>
                        <li><a href="">Disscusion</a></li>
                    </ul>
                </div>
            </div> -->
            <!-- <div class="col-lg-3 mt-4">
                <h4>Subscribe</h4>
                <div class="mt-4">
                    <p>In an ideal world this text wouldn’t exist, a client would acknowledge the importance of having
                        web copy before the design starts.</p>
                </div>
                <form class="form subscribe">
                    <input placeholder="Email" class="form-control text-white" required>
                    <a href="#" class="submit"><i class="pe-7s-paper-plane"></i></a>
                </form>
            </div> -->
        </div>
    </div>
</footer>
<!--END FOOTER-->

<!--START FOOTER ALTER-->
<div class="footer-alt">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="float-sm-start pull-none">
                    <p class="copy-rights text-muted mb-3 mb-sm-0">{{ cur_year }} © Hiric - Themesbrand</p>
                </div>
                <div class="float-sm-end pull-none copyright">
                    <ul class="list-inline d-flex flex-wrap social m-0">
                        <li class="list-inline-item"><a href="" class="social-icon ml-1"><i
                                    class="mdi mdi-facebook"></i></a></li>
                        <li class="list-inline-item"><a href="" class="social-icon ml-1"><i
                                    class="mdi mdi-twitter"></i></a></li>
                        <li class="list-inline-item"><a href="" class="social-icon ml-1"><i
                                    class="mdi mdi-linkedin"></i></a></li>
                        <li class="list-inline-item"><a href="" class="social-icon ml-1"><i
                                    class="mdi mdi-google-plus"></i></a></li>
                        <li class="list-inline-item"><a href="" class="social-icon ml-1"><i
                                    class="mdi mdi-microsoft-xbox"></i></a></li>
                    </ul>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>
<!--START FOOTER ALTER-->

<!-- Style switcher -->
<!-- <div id="style-switcher">
    <div>
        <h3>Select your color</h3>
        <ul class="pattern">
            <li>
                <a (click)="onChangeColor('cyan')" class="color1"></a>
            </li>
            <li>
                <a (click)="onChangeColor('red')" class="color2"></a>
            </li>
            <li>
                <a (click)="onChangeColor('green')" class="color3"></a>
            </li>
            <li>
                <a (click)="onChangeColor('pink')" class="color4"></a>
            </li>
            <li>
                <a (click)="onChangeColor('blue')" class="color5"></a>
            </li>
            <li>
                <a (click)="onChangeColor('purple')" class="color6"></a>
            </li>
            <li>
                <a (click)="onChangeColor('orange')" class="color7"></a>
            </li>
            <li>
                <a (click)="onChangeColor('yellow')" class="color8"></a>
            </li>
        </ul>
    </div>
    <div class="bottom">
        <a href="javascript: void(0);" id="mode" class="mode-btn text-white" (click)="onChangeMode()">
            <i class="mdi mdi-weather-sunny bx-spin mode-light"></i>
            <i class="mdi mdi-moon-waning-crescent mode-dark"></i>
        </a>
        <a (click)="onChangeSwitch()" class="settings rounded-right"><i class="mdi mdi-cog mdi-spin"></i></a>
    </div>
</div> -->